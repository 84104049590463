<template>
  <div class="playback-template">
    <div class="playback-list">
      <div class="playback-list-item" v-for="(item, index) in list" :key="item.id">
        <div class="playback-list-item-img">
          <img :src="item.img_url" v-parseImgOss="{ url: item.img_url, default: 1, type: 'img' }" />
          <div class="playback-list-item-img-filter" v-if="index === 0">
            <i class="iconfont-v3 saasicon-play"></i>
          </div>
        </div>
        <div class="playback-list-item-container">
          <p
            :class="{
              'playback-list-item-container-name': true,
              active: index === 0
            }"
          >
            {{ item.name }}
          </p>
          <p
            :class="{
              'playback-list-item-container-time': true,
              active: index === 0
            }"
          >
            <vh-icon class="vh-icon-time"></vh-icon>
            {{ item.duration }}
          </p>
        </div>
      </div>
    </div>
    <div class="tips-box">
      <span class="tips-title">温馨提示：</span>
      <!-- prettier-ignore -->
      <div class="tips-content">
        可在<span><a href="javascript:;" @click="goLink">【回放管理】</a></span>中控制观看端显示哪些内容
      </div>
    </div>
  </div>
</template>

<script>
  const staticList = [
    {
      duration: '00:02:13',
      name: '通过数字人技术形成，虚拟人物可以进行视频播报。',
      img_url: require('../../../../../common/images/custom-tab/moments-default.png')
    },
    {
      duration: '01:02:13',
      name: '一种是真人方式可录制视频制作数字人，另一种则是虚拟方式...',
      img_url: require('../../../../../common/images/custom-tab/moments-default.png')
    },
    {
      duration: '00:03:13',
      name: '播报型数字人对短视频内容进行讲解。',
      img_url: require('../../../../../common/images/custom-tab/moments-default.png')
    }
  ];
  export default {
    props: {
      menuInfo: {
        required: false
      }
    },
    name: 'component-playback',
    data() {
      return {
        file_default: '//cnstatic01.e.vhall.com/common-static/images/file_default.png',
        list: []
      };
    },
    computed: {},
    filters: {
      timeF(val) {
        const h = Math.floor(val / 1000 / 60 / 60);
        const m = Math.floor((val / 1000 / 60) % 60);
        const s = Math.floor((val / 1000) % 60);
        return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s} `;
      }
    },
    methods: {
      goLink() {
        this.$router.push({
          path: `/live/playback/${this.$route.params.str}`
        });
      },
      getWatchRecordList() {
        console.error(this.$route.params);
        this.$fetch('getWatchRecordList', {
          webinar_id: this.$route.params.str
        }).then(res => {
          this.list = res.data.list.length > 0 ? res.data.list : staticList;
        });
      }
    },
    created() {
      this.getWatchRecordList();
    }
  };
</script>

<style lang="less" scoped>
  .playback-template {
    padding: 0 8px 0 12px;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;

    .playback-list {
      &-item {
        box-sizing: border-box;
        width: 100%;
        height: 89px;
        background: rgba(0, 0, 0, 0.06);
        margin-bottom: 9px;
        position: relative;
        padding: 8px;
        border-radius: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &-img {
          width: 130px;
          height: 73px;
          display: block;
          border-radius: 4px;
          overflow: hidden;
          float: left;
          position: relative;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
          &-filter {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 1;

            i {
              color: #fb2626;
              font-size: 12px;
              position: absolute;
              left: 10px;
              bottom: 10px;
            }
          }
        }

        &-container {
          margin-left: 138px;
          &-name {
            width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            line-height: 20px;
            margin-bottom: 4px;

            &.active {
              color: #fb2626;
            }
          }

          &-time {
            height: 16px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: #595959;

            .vh-icon-time {
              margin-right: 2.5px;
            }

            &.active {
              color: #fb2626;
            }
          }
        }
      }
    }
  }
  .tips-box {
    margin-top: 80px;
    margin-left: -3px;
    margin-right: -3px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    .tips-title {
      color: #fb3a32;
      line-height: 20px;
      margin-bottom: 8px;
    }
    a {
      color: #fb3a32;
    }
  }
</style>
