var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"playback-template"},[_c('div',{staticClass:"playback-list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"playback-list-item"},[_c('div',{staticClass:"playback-list-item-img"},[_c('img',{directives:[{name:"parseImgOss",rawName:"v-parseImgOss",value:({ url: item.img_url, default: 1, type: 'img' }),expression:"{ url: item.img_url, default: 1, type: 'img' }"}],attrs:{"src":item.img_url}}),(index === 0)?_c('div',{staticClass:"playback-list-item-img-filter"},[_c('i',{staticClass:"iconfont-v3 saasicon-play"})]):_vm._e()]),_c('div',{staticClass:"playback-list-item-container"},[_c('p',{class:{
            'playback-list-item-container-name': true,
            active: index === 0
          }},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{class:{
            'playback-list-item-container-time': true,
            active: index === 0
          }},[_c('vh-icon',{staticClass:"vh-icon-time"}),_vm._v(" "+_vm._s(item.duration)+" ")],1)])])}),0),_c('div',{staticClass:"tips-box"},[_c('span',{staticClass:"tips-title"},[_vm._v("温馨提示：")]),_c('div',{staticClass:"tips-content"},[_vm._v(" 可在"),_c('span',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.goLink}},[_vm._v("【回放管理】")])]),_vm._v("中控制观看端显示哪些内容 ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }